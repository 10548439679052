<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{title}} contact</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                    Address book \ {{title}}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <form @submit.vent="createOrUpdate()">
              <v-row>
              <v-col cols="12" sm="12" md="12">
                <h4>General information</h4>
              </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.first_name"
                      outlined :error="$v.addressBook.first_name.$error"
                      dense>
                    <template v-slot:label>
                      First name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="errors.first_name" >**{{errors.first_name[0]}}</span>

                  <span class="text-danger" v-if="$v.addressBook.first_name.$error">This First Name Field is Required</span>

                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.middle_name"
                      outlined
                      dense>
                    <template v-slot:label>
                      Middle name
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.last_name"
                      outlined :error="$v.addressBook.last_name.$error"
                      dense>
                    <template v-slot:label>
                     Last name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.last_name.$error">This Last Name Field is Required</span>
                  <span class="text-danger" v-if="errors.last_name" >**{{errors.last_name[0]}}</span>

                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <h4>Contact Information</h4>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="addressBook.email"
                            outlined :error="!$v.addressBook.email.email"
                            dense>
                        <template v-slot:label>
                            Email <span class="text-danger"></span>
                        </template>
                    </v-text-field>
                    <span class="text-danger" v-if="!$v.addressBook.email.email">This Email is invalid</span>
                </v-col>

                  <v-col cols="12" md="6">
                  <v-text-field
                      v-model="addressBook.website"
                      outlined
                      dense>
                    <template v-slot:label>
                      Website <span class="text-danger"></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.phone"
                      outlined
                      dense>
                    <template v-slot:label>
                      Phone <span class="text-danger"></span>
                    </template>
                  </v-text-field>


                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.mobile"
                      :error="$v.addressBook.mobile.$error"
                      outlined
                      dense>
                    <template v-slot:label>
                     Mobile
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.addressBook.mobile.$error">This Mobile Field is Required</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="addressBook.work_phone"
                      outlined
                      dense>
                    <template v-slot:label>
                      Work phone
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                      v-model="addressBook.category_id"
                      :error="$v.addressBook.category_id.$error"
                      :items="addressBookCategories"
                      item-text="title"
                      item-value="id"
                      outlined
                      dense>
                    <template v-slot:label>
                      Category <span class="text-danger">*</span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                      v-model="addressBook.branch_id"
                      :items="ourBranches"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense>
                    <template v-slot:label>
                      Branch <span class="text-danger"></span>
                    </template>
                  </v-select>
                </v-col>
<!--                <v-col cols="12" sm="12" md="12">-->
<!--                  <h4>Address Information</h4>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="6">-->
<!--                  <v-text-field-->
<!--                      v-model="addressBook.address_line_1"-->
<!--                      outlined-->
<!--                      dense>-->
<!--                    <template v-slot:label>-->
<!--                      Address Line 1 <span class="text-danger"></span>-->
<!--                    </template>-->
<!--                  </v-text-field>-->

<!--                </v-col>-->
<!--                <v-col cols="12" md="6">-->
<!--                  <v-text-field-->
<!--                      v-model="addressBook.address_line_2"-->
<!--                      outlined-->
<!--                      dense>-->
<!--                    <template v-slot:label>-->
<!--                      Address Line 2-->
<!--                    </template>-->
<!--                  </v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="6">-->
<!--                  <v-text-field-->
<!--                      v-model="addressBook.suburb"-->
<!--                      outlined-->
<!--                      dense>-->
<!--                    <template v-slot:label>-->
<!--                      Suburb <span class="text-danger"></span>-->
<!--                    </template>-->
<!--                  </v-text-field>-->

<!--                </v-col>-->
<!--                <v-col cols="12" md="6">-->
<!--                  <v-text-field-->
<!--                      v-model="addressBook.post_code"-->
<!--                      outlined-->
<!--                      dense>-->
<!--                    <template v-slot:label>-->
<!--                      Post Code <span class="text-danger"></span>-->
<!--                    </template>-->
<!--                  </v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="6">-->
<!--                  <v-autocomplete @change="toggleCountry"-->
<!--                                  v-model="addressBook.country_id"-->
<!--                                  :items="countries" filled-->
<!--                                  outlined-->
<!--                                  dense color="blue-grey lighten-2"-->
<!--                                  label="Select Country"-->
<!--                                  item-text="title"-->
<!--                                  item-value="id">-->

<!--                  </v-autocomplete>-->

<!--                </v-col>-->
<!--                <v-col cols="12" sm="12" md="6" v-if="toggle.stateList==false">-->
<!--                  <v-text-field label="State*" outlined dense-->
<!--                                v-model="addressBook.state">-->
<!--                    <template v-slot:label>-->
<!--                     State <span class="text-danger"></span>-->
<!--                    </template>-->
<!--                  </v-text-field>-->

<!--                </v-col>-->
<!--                <v-col cols="12" sm="12" md="6" v-if="toggle.stateList">-->
<!--                  <v-autocomplete-->
<!--                      v-model="addressBook.state_id"-->
<!--                      :items="states" filled-->
<!--                      outlined-->
<!--                      dense color="blue-grey lighten-2"-->
<!--                      label="Select States"-->
<!--                      item-text="name"-->
<!--                      item-value="id">-->
<!--                    <template v-slot:label>-->
<!--                      State <span class="text-danger"></span>-->
<!--                    </template>-->
<!--                  </v-autocomplete>-->

<!--                </v-col>-->
                <v-col cols="12" sm="12" md="12">
                  <ckeditor :config="editorConfig"
                            v-model="addressBook.remarks" >
                  </ckeditor>
                </v-col>
                <v-col cols="6" md="4">
                  Status
                  <v-switch
                      v-model="addressBook.is_active"
                      :label="addressBook.is_active?' Active ':'Inactive'"
                      color="primary"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn
                      color="mr-2" text dark
                      medium
                      @click="back">
                    Cancel
                  </v-btn>
                  <v-btn v-if="checkIsAccessible('address-book', 'create')" color="ml-4 btn btn-primary" medium @click="createOrUpdate()" :loading="loading">
                        {{edit ? 'Update' : 'Save'}}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import AddressBookService from "@/services/address-book/AddressBookService";
import CountryService from "@/services/country/CountryService";
import StateService from "@/services/country/state/StateService";
import {required, email } from "vuelidate/lib/validators";
import AddressBookCategoryService from "@/services/address-book/category/AddressBookCategoryService";

const state = new StateService;
const country = new CountryService();
const addressBook = new AddressBookService;
const addressBookCategory = new AddressBookCategoryService();
export default {
  validations:{
    addressBook:{
      first_name:{required},
      last_name:{required},
      email:{email},
      mobile:{required},
      category_id:{required},
    },
  },
  data(){
    return{
      title:'',
      loading:false,
      edit:false,
      countries:[],
      states:[],
      addressBookCategories:[],
      addressBook:{
        first_name:'',
        middle_name:'',
        last_name:'',
        email:'',
        address_line_1:'',
        address_line_2:'',
        post_code:'',
        suburb:'',
        country_id:'',
        state:'',
        state_id:'',
        remarks:'',
        is_active:true,
      },
      toggle:{
        stateList:true
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      errors:[],
    }
  },
  mounted() {
    this.getCountries();
    this.getAllCategories();
    this.addressBookDetail();
    this.getAllBranches();
  },
  computed:{
    addressBookId() {
      return this.$route.params.addressBookId;
    },
  },
  methods:{
    getAllCategories() {
      addressBookCategory.all().then(response => {
        this.addressBookCategories = response.data.addressBookCategories;
      })
      .catch(() => {
      });
    },
    toggleCountry(){
      this.toggle.stateList = this.addressBook.country_id == 14;
      this.getAllState()
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (this.addressBookId != null || this.addressBookId != undefined) {
          addressBook
              .update(this.addressBookId, this.addressBook)
              .then(response => {
                this.$snotify.success("AddressBook updated successfully");
                this.back();
                this.loading = false;
                this.addressBookDetail();
              })
              .catch(error => {
                this.loading = false;
                this.errors = error.errors;
              })
        } else {
          this.loading = true;
          addressBook
              .create(this.addressBook)
              .then(response => {
                this.$snotify.success("AddressBook created successfully");
                this.loading = false;
                this.back();
              })
              .catch(error => {
                this.loading = false;
                this.errors = error.errors;
              })
        }
      }
    },
    back() {
      this.$tabs.open({
        name: "address-book",
      });
      this.$tabs.close();
    },

    getAllState()
    {
      if(this.addressBook.country_id) {
        state
            .all(this.addressBook.country_id)
            .then(response => {
              this.states = response.data.data;
            })
            .catch((err) => {
            });
      }
    },
    getCountries(){
      country
          .getAllCountry()
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {
          })
    },

    addressBookDetail() {
      if(this.addressBookId != null || this.addressBookId !=undefined ) {
        this.title = 'Edit';
        this.edit = true;
        addressBook
            .show(this.addressBookId)
            .then(response=> {
              this.addressBook = response.data.addressBook;
              this.addressBook.is_active=this.addressBook.is_active==1 ? true :false;
              this.toggleCountry();
            })
        }else {
          this.title = 'Add';
          this.edit = false;
          this.addressBook = {
          first_name: '',
          middle_name: '',
          last_name: '',
            email: '',
          address_line_1: '',
          address_line_2: '',
          post_code: '',
          suburb: '',
          country_id: 14,
          state: '',
          state_id: '',
          remarks: '',
          is_active: true,
        };
      }
    }
  },
}
</script>
